import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Button, { ButtonSizes, ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import { Routes } from "../../../app/route/RoutesConfig";
import ReportsState from "../../../slices/reports/ReportsState";
import { reportsSelector, getReports } from "../../../slices/reports/reportsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import { frequencyTypes } from "./constants";
import { Card } from "../../../ui-components/card/card";
import { ReactComponent as ChartIcon } from "../../../images/chart.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus.svg";

import "./scheduledReports.scss";

const ScheduledReports: React.FC<{}> = (props) => {
  const reportsState: ReportsState = useSelector(reportsSelector);
  const userState: UserState = useSelector(userSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  const onCreateButtonClick = () => {
    history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.reports.scheduled.create}`);
  };

  const openReport = (id?: string) => {
    id && history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.reports.scheduled.list}/${id}`);
  };

  useEffect(() => {
    userState.activeSubAccount?.id && dispatch(getReports(userState.activeSubAccount.id));
  }, [dispatch, userState.activeSubAccount]);

  useEffect(() => {
    if (!userState.activeSubAccount?.id || !userState.profile?.subAccounts) return;
    if (!userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor) {
      history.push(Routes.home);
    }
  }, [history, userState.profile, userState.activeSubAccount]);

  return (
    <ContentContainer title="Scheduled Reports">
      <Helmet title="Scheduled Reports" />
      <div className="xgs-reports__scheduled">
        {reportsState.requestStarted && (
          <div className="xgs-reports__scheduled__list-spinner">
            <Loading isLoading={true} />
          </div>
        )}
        {reportsState.requestSucceed && (
          <>
            {(!reportsState.reports || reportsState.reports.length === 0) && (
              <div className="xgs-reports__scheduled__no-reports">
                You do not have any scheduled reports.<br/>
                You can add new daily, weekly and monthly reports on this page.
              </div>
            )}

            <div className="xgs-reports__scheduled__reports">
            {reportsState.reports && reportsState.reports.length > 0 && reportsState.reports.map((report) =>
              <Card
                hideButtons={true}
                icon={
                  <div className="xgs-reports__scheduled__card__icon">
                    <ChartIcon />
                  </div>
                }
                title={report.name}
                subtitle={frequencyTypes.find((type: { value: string, label: string }) => type.value === report.frequency)?.label}
                key={report.id}
                onClick={() => { openReport(report.id) }}
                mix="xgs-reports__scheduled__card"
              >

                <div className="xgs-reports__scheduled__card__row">                  
                    <span className="xgs-reports__scheduled__card__label">Created at:</span>
                    <span>{report.dateCreated?.toUiDateFormat()}</span>       
                  
                    <span className="xgs-reports__scheduled__card__label">Created by:</span>
                    <span className="xgs-reports__scheduled__card__row-truncated">{report.userCreated}</span>                  
                </div>
                <div className="xgs-reports__scheduled__card__row">
                  <span className="xgs-reports__scheduled__card__label">Recipients:</span>
                  <span className="xgs-reports__scheduled__card__row-truncated">{report.email1}{report.email2 && `, ${report.email2}`}{report.email3 && `, ${report.email3}`}</span>
                </div>
              </Card>              
            )}
            </div>            
          </>
        )}
        {(!reportsState.reports || reportsState.reports.length < 11) && (
          <Button
            theme={ButtonThemes.blue}
            size={ButtonSizes.autoMedium}
            onClick={onCreateButtonClick}
            className="xgs-reports__scheduled__add-new-button"
          >
            <>
              <div className="xgs-btn__icon-container">
                <PlusIcon className="xgs-btn__icon" />
              </div>
              Add new report
            </>
          </Button>
        )}
      </div>
    </ContentContainer>
  );
};

export default ScheduledReports;
