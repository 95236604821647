import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import { openMobileSideMenu, closeMobileSideMenu } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import SubAccountSelector from "../sub-account-selector/subAccountSelector";
import TerminalSelector from "../terminal-selector/terminalSelector";
import logo from "./../../images/logo.svg";
import menu from "./../../images/menu.svg";
import menu_close from "./../../images/menu_close.svg";
import { publicRoutesComponents } from "../../app/route/RoutesConfig";
import UserMenu from "./user-menu/userMenu";
import { UserUtils } from "../../app/data/user/userUtils";
import Feedback from "../feedback/feedback";
import "./header.scss";

const Header: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();
  const location = useLocation();

  const isPublicPage = () => {
    return publicRoutesComponents.find((route) => {
      return location.pathname.startsWith(route.fixedPath ? route.fixedPath : (Array.isArray(route.path) ? route.path[0] : `${route.path}`));
    });
  };

  useEffect(() => {
    const bodyEl = document.body;
    const menuEl = document.querySelectorAll(".xgs-site__menu-wrapper")[0];
    if (!bodyEl || !menuEl) return;
    if (userState.showMobileSideMenu) {
      bodyEl.classList.add("xgs-site--menu-opened");
      menuEl.classList.add("xgs-site__menu-wrapper--opened");
    } else {
      bodyEl.classList.remove("xgs-site--menu-opened");
      menuEl.classList.remove("xgs-site__menu-wrapper--opened");
    }
  }, [dispatch, userState.showMobileSideMenu]);

  return (
    <div className="xgs-header">
      <div className="xgs-header__content">
        <div
          className={`xgs-header__start ${
            (!userState?.loggedIn && !userState?.loginProcess) || isPublicPage()
              ? "xgs-header__signin"
              : ""
          } ${userState?.loggedIn && !isPublicPage()
              ? "right-border"
              : ""
          }`}
        >
          {userState?.loggedIn && !isPublicPage() && (
            <div className="xgs-header__menu-icon__wrapper">
              {!userState.showMobileSideMenu && (
                <img className="xgs-header__menu-icon__image" src={menu} alt="Open menu" onClick={() => dispatch(openMobileSideMenu())} />
              )}
              {userState.showMobileSideMenu && (
                <img className="xgs-header__menu-icon__image" src={menu_close} alt="Close menu" onClick={() => dispatch(closeMobileSideMenu())} />
              )}
            </div>
          )}
          <div className="xgs-header__logo">
            {!isPublicPage() && (
              <NavLink to={UserUtils.isEmployee(userState.profile) ? "/shipments" : "/"} onClick={() => dispatch(closeMobileSideMenu())}>
                <img className="xgs-logo" src={logo} alt="xgsi.com logo" />
              </NavLink>
            )}
            {isPublicPage() && (
              <a href="/">
                <img className="xgs-logo" src={logo} alt="xgsi.com logo" />
              </a>
            )}
          </div>
        </div>
        <div className="xgs-header__middle">
          {userState?.loggedIn && !isPublicPage() && (
            <div className="xgs-header__selector">
              {UserUtils.isCustomer(userState.profile) && <SubAccountSelector />}
              {UserUtils.isEmployee(userState.profile) && <TerminalSelector />}
            </div>
          )}
          <div className="xgs-header__mobile-logo">
            {!isPublicPage() && (
              <NavLink to="/" onClick={() => dispatch(closeMobileSideMenu())}>
                <img className="xgs-logo" src={logo} alt="xgsi.com logo"></img>
              </NavLink>
            )}
            {isPublicPage() && (
              <a href="/">
                <img className="xgs-logo" src={logo} alt="xgsi.com logo" />
              </a>
            )}
          </div>
        </div>
        <div className="xgs-header__end">
          {userState?.loggedIn && !isPublicPage() && (
            <>
              <UserMenu />
            </>
          )}
        </div>
      </div>
      {userState?.loggedIn && <Feedback mix="xgs-header__feedback"/>}
    </div>
  );
};

export default Header;
