import React from "react";
import { ReactComponent as AIIcon } from "../../../../images/ai.svg";
import { cn } from "../../../../services/common/className";
import UserState from "../../../../slices/user/UserState";
import { useSelector } from "react-redux";
import { userSelector } from "../../../../slices/user/userSlice";
import { ChatMessageModel, ChatPromptModel } from "../../../../app/data/chat/models";
import Tag from "../../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../../app/data/common/tagColor";

export interface ChatMessageProps {
  message: ChatMessageModel,
  mix?: string,
  onPromptClick?: (prompt: ChatPromptModel) => void;
  isLoading?: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message, mix, onPromptClick, isLoading }) => {
  const userState: UserState = useSelector(userSelector);

  return (
    <div className={cn("xgs-chat__message")({ incoming: message.incoming, outcoming: !message.incoming }, mix)}>
      <div className="xgs-chat__message__user-wrapper">
        <div className="xgs-chat__message__user">
          {message.incoming ? <AIIcon /> : userState.profile?.firstName.slice(0, 1)}
        </div>
      </div>

      <div className="xgs-chat__message__content">
        <div className="xgs-chat__message__text">
          {isLoading ? (
            <div className="xgs-chat__message__loader"/>
          ) : message.text}
        </div>

        {message.prompts?.length && (
          <div className="xgs-chat__message__prompts">
            {message.prompts.map(prompt => (
              <Tag key={prompt.promptId} mods={{ color: TagColor.BLUE }} onClick={() => { onPromptClick?.(prompt) }}>{prompt.promptMessage}</Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
